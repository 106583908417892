/**
 * This file is used to build a separate Webpack CSS bundle for
 * responsive-specific CSS so that we can load this CSS conditionally
 * when ?responsive_header_footer and/or similar feature flags are enabled.
 *
 * See tools/webpack/webpack.client.config.js which configures this as a
 * Webpack entry point.
 */

import 'components/ResponsiveGrid/ResponsiveGrid.scss';
import 'domains/Responsive/ResponsiveHeaderLayout.scss';
